<template>
  <div>
    <Modal
      v-model="showPreviewModal"
      title="Preview da Prova"
      :enableClose="true"
    >
      <div v-if="instituicao">
        <div class="row">
          <div class="col-md-6 d-flex justify-content-md-end">
            <ul class="list-inline m-0">
              <li class="list-inline-item">
                <button
                  class="btn btn-outline"
              id="provaReprovar"
                  @click.prevent="fluxoStatus('Rascunho', provaPreview)"
                >
                  Reprovar
                </button>
              </li>
            </ul>
          </div>
          <div class="col-md-6 d-flex">
            <ul class="list-inline m-0">
              <li class="list-inline-item">
                <button
              id="provaAprovar"

                  class="btn btn-classic"
                  style="width: 100%"
                  @click.prevent="fluxoStatus('Aprovado', provaPreview)"
                >
                  Aprovar
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PreviewQuestoes :prova="provaPreview"> </PreviewQuestoes>
    </Modal>

    <main-top-bar />
    <div class="d-flex main-wrapper" :class="{ toggled: !showLeftMenu }">
      <main-left-side-bar />

      <main id="content" class="main-content" role="main">
        <div class="container-fluid pt-4 px-4">
          <div
            class="
              page-header
              d-flex
              justify-content-between
              align-items-center
              mb-3
              pt-4
            "
          >
            <h1 class="color-sec">Provas</h1>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="">Home</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Provas
                </li>
              </ol>
            </nav>
          </div>

          <div class="page-content">
            <div class="page-content__filter block__classic mb-2">
              <div class="row">
                <div class="col-md-9">
                  <form class="block__form">
                    <div class="form-row">
                      <div class="col-auto">
                        <input
                          id="search"
                          v-model="filter"
                          type="search"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>

                <div
                  class="col-md-3 d-flex justify-content-md-end"
                  v-if="criarPova"
                >
                  <ul class="list-inline m-0">
                    <li class="list-inline-item">
                      <router-link to="/criar-prova" class="btn btn-classic">
                        Criar Prova
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <table
                id="provas"
                class="
                  block__classic
                  table table-striped table-borderless table-hover
                  text-center
                "
              >
                <thead>
                  <tr>
                    <th style="text-align: left; margin-left: 10px">
                      Componente Curricular
                    </th>
                    <th style="text-align: left; margin-left: 10px">Ano</th>
                    <th style="text-align: left; margin-left: 10px">Turma</th>
                    <th style="text-align: left; margin-left: 10px">Nome</th>
                    <th>Status</th>
                    <th style="text-align: left; margin-left: 10px" v-if="instituicao"></th>
                    <th class="no-sort">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in filteredRows" :key="item.id" >
                    <td class="text-left" v-html="item.disciplinaText" @click.prevent="editProva(item)"></td>
                    <td @click.prevent="editProva(item)"
                      class="text-left"
                      v-html="item.turmaAnoTitulo"
                    />
                    <td class="text-left" v-html="item.turmaTitulo" @click.prevent="editProva(item)" />
                    <td @click.prevent="editProva(item)"
                      class="text-left"
                      v-html="`<strong>${highlightMatches(item.titulo)}</strong`"
                    />
                    <td class="text-center pl-4" @click.prevent="editProva(item)">
                      <ProvaStatus :status="{codigo: item.statusCodigo, descricao: item.statusDescricao}" />
                    </td>
                    <td class="text-center pl-4" v-if="instituicao">
                      <button @click.prevent="disponibilizar(item)" id="provaDisponibilizar" class="btn btn-classic" v-if="item.statusCodigo == 'EmCorrecao'">
                        Disponibilizar
                      </button>
                    </td>
                    <td class="text-center pl-4">
                      <MenuTableOptions
                        :prova="item"
                        v-on:showModalPreview="modal($event, item)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <pagination
                v-if="filteredRows.length"
                :offset="offset"
                :total="total"
                :limit="limit"
                @change-page="changePage"
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Router from "vue-router";
import MainLeftSideBar from "@/components/menu/MainLeftSideBar";
import MainTopBar from "@/components/menu/MainTopBar";
import Swal from "sweetalert2";
import { constantes } from "@/constants";
import { checkUserHaveRole } from "@/tools";
import Pagination from "@/components/pagination/Pagination";
import ProvaStatus from "@/components/provas/ProvaStatus";
import MenuTableOptions from "@/components/menu/MenuTableOptions";
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import PreviewQuestoes from "@/modules/provas/pages/PreviewQuestoes";

export default {
  components: {
    Pagination,
    MainTopBar,
    MainLeftSideBar,
    ProvaStatus,
    MenuTableOptions,
    Modal: VueModal,
    PreviewQuestoes,
  },
  data() {
    return {
      offset: 0,
      total: 0,
      instituicao: false,
      limit: 50,
      showLeftMenu: true,
      hasFilter: this.$route.params.filter ? this.$route.params.filter : false,
      provas: [],
      filter: "",
      criarPova: false,
      showPreviewModal: false,
      provaPreview: Object,
      user: [],
    };
  },
  computed: {
    filteredRows() {
      return this.provas.filter((row) => {
        return this.filter
          .toLowerCase()
          .split(" ")
          .every((v) => row.titulo.toLowerCase().includes(v));
      });
    },
  },
  created() {
    this.$root.$on("Dashboard::toggleLeftMenu", () => {
      this.showLeftMenu = !this.showLeftMenu;
    });
    if (checkUserHaveRole([constantes.ROLE_INSTITUICAO])) {
      this.instituicao = true;
    }
    this.loadData();
    // this.loadUser();
  },
  methods: {
    modal(e, item) {
      debugger
      // console.log("here", e)
      this.showPreviewModal = true;
      this.provaPreview = item;
    },
    changePage(value) {
      this.offset = value;
      this.getPassengers();
    },
    highlightMatches(text) {
      if(!this.filter || !this.filter.toLowerCase()){
        return text;
      }
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) =>
          `<strong style="color: #26A54E !important;">${matchedText}</strong>`
      );
    },
    disponibilizar(prova){
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma disponibilizar essa prova?",
        // text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonColor: "#35dc8b",
        cancelButtonColor: "#999",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$api
            .post("/provas-fluxo-status/" + prova.id, {
              status: 'Aprovado',
              prova: prova.id,
            })
            .then((response) => {
              console.log(response);
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                icon: "success",
                title: "Prova " + status + "!",
                showConfirmButton: false,
                timer: 1500,
                position: "center",
              }).then(() => {
                location.reload();
              });
              setTimeout(function () {
                location.reload();
              }, 2000);
            })
            .catch((error) => {
              console.log(error.response);

              if (error.response.status == 500) {
                Swal.fire({
                  icon: "success",
                  title: "Prova " + status + "!",
                  showConfirmButton: false,
                  timer: 1500,
                  position: "center",
                }).then(() => {
                  location.reload();
                });
                setTimeout(function () {
                  location.reload();
                }, 2000);
              }
            });
        }
      });
    },
    // loadQuestoesProvas() {
    //   this.provas.forEach((prova, index) => {
    //     this.$api
    //       .get('questoes-prova/' + prova.id)
    //         .then((response) => {
    //           this.provas[index].provaQuestoes = response.data
    //         })
    //         .catch((error) => {
    //           console.log(error.response);
    //         });

    //   })

    // },
    loadData() {
      let roles = localStorage.getItem(constantes.STORAGE_UNIAVAN_USER_ROLES);
      let idInstituicao = localStorage.getItem(
        constantes.STORAGE_UNIAVAN_ID_INSTITUICAO
      );
      let arrayRoles = roles.split(",");
      let endpoint = "";
      console.log(arrayRoles);
      if (arrayRoles.includes("ROLE_PROFESSOR")) {
        this.criarPova = true;
      }
      endpoint = "provas-criadas";
      let filtro = "";
      if (
        this.$route.params.filter != "" &&
        this.$route.params.filter != undefined
      ) {
        filtro = "/" + this.$route.params.filter;
      }
      this.$api
        .get(endpoint + filtro)
        .then((response) => {
          if (arrayRoles.includes("ROLE_PROFESSOR")) {
            this.$api
              .get("users/info")
              .then((user) => {
                this.provas = response.data.filter((res) => {
                  return res.criadaPorEmail == user.data.user.email && !res.trashAt;
                });
                // this.loadQuestoesProvas();
                console.log(this.provas);
              })
              .catch((error) => {
                console.log(error);
                console.log(error.response);
              });
          } else {
            console.log(response);
            this.provas = response.data.filter((res) => {
              return res.statusCodigo != "Rascunho" && !res.trashAt && res.turmaInstituicaoId == idInstituicao;
            });
            // this.loadQuestoesProvas();
          }

          this.$root.$emit("Spinner::hide");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    deletar(id, index) {
      this.$api
        .put("provas/" + id + "/trash", {})
        .then((response) => {
          this.$root.$emit("Spinner::hide");
          this.provas.splice(index, 1);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Prova deletada com sucesso!",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    editProva(prova){
      if(prova.statusCodigo == "Rascunho"){
        this.$router.push({ name: "provas_edit", params: { id: prova.id } });
      }
    },
    fluxoStatus(status, prova) {
      const descricao = status.toLowerCase()
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Confirma mudar o status dessa prova para " + descricao + "?",
        // text: "Essa ação não pode ser revertida!",
        showCancelButton: true,
        confirmButtonClass: "proj-botao-padrao",
        cancelButtonColor: "#999",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar!",
        buttonsStyling: true,
      }).then((res) => {
        if (res.isConfirmed) {
          this.$api
            .post("/provas-fluxo-status/" + prova.id, {
              status: status,
              prova: prova.id,
            })
            .then((response) => {
              console.log(response);
              this.$root.$emit("Spinner::hide");

              Swal.fire({
                icon: "success",
                title: "Prova " + descricao + "!",
                showConfirmButton: false,
                timer: 1500,
                position: "center",
              }).then(() => {
                location.reload();
              });
              setTimeout(function () {
                location.reload();
              }, 2000);
            })
            .catch((error) => {
              console.log(error.response);

              if (error.response.status == 500) {
                Swal.fire({
                  icon: "success",
                  title: "Prova " + descricao + "!",
                  showConfirmButton: false,
                  timer: 1500,
                  position: "center",
                }).then(() => {
                  location.reload();
                });
                setTimeout(function () {
                  location.reload();
                }, 2000);
              }
            });
        }
      });
    },
    editar(index) {
      this.$router.push({ name: "provas_edit", params: { id: index } });
    },
    listaProvaCorrigir(index) {
      this.$router.push({
        name: "provas_para_correcao",
        params: { id: index },
      });
    },
  },
};
</script>

<style>
.vm {
  width: 700px !important;
}
</style>
